<!--
 * @Autor: syq
 * @Date: 2021-07-09 17:31:23
-->
<template>
  <van-search
    v-model="searchData"
    placeholder="请输入商品名称/条形码或店铺名称进行搜索"
    input-align="center"
    show-action
    shape="round"
    left-icon=""
    right-icon=""
    clearable
    @change="change()"
  >
    <template #action>
      <div class="search-action">
        <van-icon name="search" @click="change" size="18" />
      </div>
    </template>
  </van-search>
</template>

<script>
export default {
  props: { list: Array },
  data() {
    return {
      searchData: "",
    };
  },
  mounted() {},
  methods: {
    change() {
      this.$parent.queryItemList(this.searchData);

      if (this.$parent.stoteItemList) {
        this.$parent.stoteItemList(this.searchData);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.van-search {
  background-color: inherit;
}
.search-action {
  position: relative;
  top: 5px;
}
</style>
