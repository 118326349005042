<!--
 * @Description: 
 * @Author: rodchen
 * @Date: 2021-07-20 21:10:51
 * @LastEditTime: 2021-08-09 13:58:40
 * @LastEditors: Please set LastEditors
-->
<template>
  <div id="storetheme">
    <div class="saerch">
      <Search />
    </div>

    <div class="main">
      <div class="main-top">
        <div>综合</div>
        <div class="placeroot">
          按价格&nbsp;
          <span class="main-top-price">
            <van-icon name="arrow-up" size="12" />
            <van-icon name="arrow-down" size="12" />
          </span>
        </div>

        <div>按销量</div>
      </div>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="getMessageList()"
      >
        <div class="main-bottom">
          <van-grid :column-num="2" class="fourInarow-details">
            <van-grid-item
              v-for="item in items"
              :key="item.id"
              @click="() => shopdetails(item)"
            >
              <van-image :src="findUrl(item)" width="45%" height="71" />
              <p class="goods-title">{{ item.name }}</p>
              <p class="goods-price">￥{{ item.price }}</p>
            </van-grid-item>
          </van-grid>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import Search from "../../../components/Search.vue";
import { getItemList, getTotalItemList } from "./service";

export default {
  components: { Search },
  data() {
    return {
      status: 1,
      items: [],
      loading: false,
      finished: false,
      currentPage: 0,
    };
  },
  mounted() {
    this.queryItemList(
      this.$route.query.brandId,
      this.$route.query.classId,
      this.$route.query.spuid
    );
  },
  methods: {
    getMessageList() {
      this.loading = true;
      if (this.$route.query.themId) {
        getItemList(++this.currentPage).then((res) => {
          this.loading = false;
          if (res.status === "0") {
            this.items = this.items.concat(res.data?.items || []);
            this.currentPage = res.data?.page;
            if (!res?.data || res.data.items?.length < 10) {
              this.finished = true;
            }
          }
        });
      } else {
        getTotalItemList(++this.currentPage).then((res) => {
          this.loading = false;
          if (res.status === "0") {
            this.items = this.items.concat(res.data?.items || []);
            this.currentPage = res.data?.page;
            if (!res.data || res.data?.items?.length < 10) {
              this.finished = false;
            }
          }
        });
      }
    },

    queryItemList(brandId, classid, spuid) {
      this.loading = true;
      getTotalItemList(++this.currentPage, brandId, classid, spuid)
        .then((res) => {
          this.loading = false;
          if (res.status === "0") {
            this.items = this.items.concat(res?.data?.items || []);
            this.currentPage = res?.data?.page;
            if (!res.data || res.data?.items?.length < 10) {
              this.finished = true;
            }
          } else {
            this.loading = false;
            this.finished = true;
            this.items = [];
            Toast(res.msg);
          }
        })
        .catch((err) => {
          this.loading = false;
          this.finished = true;
          this.items = [];

          Toast(err.msg);
        });
    },
    findUrl(item) {
      return item?.mediaList ? item.mediaList[0].url : undefined;
    },
    shopdetails(item) {
      const { itemCode, storeCode } = item;
      this.$router.push({
        path: "/shopdetails",
        query: { itemCode, storeCode },
      });
    },
  },
};
</script>

<style lang="less">
#storetheme {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  .main-top {
    display: flex;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    .placeroot {
      display: flex;
      justify-content: center;
    }
    .main-top-price {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 10px;
      line-height: 40px;
    }
  }

  .main-top > div {
    width: 33%;
    text-align: center;
  }

  .fourInarow-details {
    .goods-title {
      width: 100%;
      height: 18px;
      line-height: 14px;
      font-size: 12px;
    }
    .goods-price {
      margin: 8px 0;
      width: 100%;
      height: 16px;
      line-height: 16px;
      color: red;
    }
    /deep/.van-grid-item__content {
      padding: 0 0;
    }
  }
}
</style>
